import React from "react";
import { connect } from "react-redux";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./BackgroundVideo.css";

class BackgroundVideo extends React.Component {
  render() {
    return (
      <div className="novideo">
        <video autoPlay muted className={s.bgVideo}>
          <source src={require("../../static/max.mp4")} type="video/mp4" />
        </video>
      </div>
    );
  }
}

const mapState = (state) => ({});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(withStyles(s)(BackgroundVideo));
